import './App.css';
import { Admin, Resource } from 'react-admin';
import DataProvider from './DataProvider';
import Users from './users';
import Accounts from './accounts';
import Orders from './orders';
import Products from './products';
import OrderStatus from './order_status';
import PackagingCosts from './packaging_costs';
import PurchasePrices from './purchase_prices';
import MonthlyReports from './monthly_reports';
import QuarterlyReports from './quarterly_reports';
import YearlyReports from './yearly_reports';
import OtherExpenses from './other_expenses';
import AuthProvider from './AuthProvider';

import CustomLayout from './CustomLayout';

const dataProvider = DataProvider('api/admin');

function App() {
  return (
    <div className="App">
      <Admin layout={CustomLayout} authProvider={AuthProvider} dataProvider={dataProvider}>
        <Resource name="users" {...Users} />
        <Resource name="accounts" {...Accounts} />
        <Resource name="orders" {...Orders} />
        <Resource name="products" {...Products} />
        <Resource name="order_status" {...OrderStatus} />
        <Resource name="packaging_costs" {...PackagingCosts} />
        <Resource name="purchase_prices" {...PurchasePrices} />
        <Resource name="other_expenses" {...OtherExpenses} />
        <Resource name="monthly_reports" {...MonthlyReports} />
        <Resource name="quarterly_reports" {...QuarterlyReports} />
        <Resource name="yearly_reports" {...YearlyReports} />
      </Admin>
    </div>
  );
}

export default App;
